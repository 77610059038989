import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(`../views/index.vue`)
  },
  // 产品使用说明书
  {
    path: '/instructions-:productSn',
    name: 'instructions',
    component: () => import(`../views/instructions.vue`)
  },
  // 售后服务网点
  {
    path: '/service-address-:addressId',
    name: 'address',
    component: () => import(`../views/address.vue`)
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'development'? '/' : '/uniqueCode/',
  routes
})

export default router
